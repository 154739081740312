import { PartialJournalType } from 'src/enums/partial-journal-type';
import * as Yup from 'yup';
import { PartialJournal, PersonType, Supplier } from '@models';

export function useValidationSchema() {
  return Yup.object<PartialJournal>().shape({
    supplier: Yup.mixed<Supplier>().nullable().required(),
    leader: Yup.mixed<PersonType>().nullable().required(),
    type: Yup.string<PartialJournalType>().nullable().required(),
  });
}

import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DetailContext,
  DetailHandle,
  DetailMode,
  FormAutocomplete,
  FormPanel,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { useProfessions } from '@modules/dict__profession/profession-api';
import { useSuppliers } from '@modules/suppliers/supplier-api';
import { useTenants } from '@modules/tenant/tenant-api';
import { PersonType } from '@models';

export function Fields() {
  const { mode } = useContext<DetailHandle<PersonType>>(DetailContext);

  const tenant = useFormSelector((value: PersonType) => value.tenant);

  const professionSource = useProfessions();
  const suppliers = useSuppliers();
  const tenants = useTenants();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__OVERVIEW"
            defaultMessage="Základné údaje"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Meno"
            />
          }
          required
        />
        <FormTextField
          name="surname"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__SURNAME"
              defaultMessage="Priezvisko"
            />
          }
          required
        />
        <FormTextField
          name="email"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__EMAIL"
              defaultMessage="E-mail"
            />
          }
        />
        {tenant?.code === 'ZSD' && (
          <FormTextField
            name="kid"
            label={
              <FormattedMessage
                id="ESD__PERSON__DETAIL__FIELD_LABEL__KID"
                defaultMessage="KID"
              />
            }
            disabled={mode === DetailMode.EDIT}
          />
        )}
        {tenant?.code === 'VSD' && (
          <FormTextField
            name="personalNumber"
            label={
              <FormattedMessage
                id="ESD__PERSON__DETAIL__FIELD_LABEL__PERSONAL_NUMBER"
                defaultMessage="Osobné číslo"
              />
            }
            disabled={mode === DetailMode.EDIT}
          />
        )}
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__WORK_INFO"
            defaultMessage="Pracovné údaje"
          />
        }
      >
        <FormTextField
          name="team"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__TEAM"
              defaultMessage="Tím"
            />
          }
        />
        <FormAutocomplete
          name="profession"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__PROFESSION"
              defaultMessage="Pracovná pozícia"
            />
          }
          source={professionSource}
        />
        <FormAutocomplete
          name="supplier"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__SUPPLIER"
              defaultMessage="Dodávateľ"
            />
          }
          source={suppliers}
          disabled
        />
        <FormAutocomplete
          name="tenant"
          label={
            <FormattedMessage
              id="ESD__PERSON__DETAIL__FIELD_LABEL__TENANT"
              defaultMessage="Vlastník"
            />
          }
          source={tenants}
          disabled={mode === DetailMode.EDIT}
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__PERSON__DETAIL__PANEL_TITLE__HISTORY"
            defaultMessage="História"
          />
        }
      >
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
    </>
  );
}

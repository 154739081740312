import {
  ApiFilterOperation,
  Filter,
  ListSource,
  Params,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { PersonAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

/**
 * This autocomplete returns values, which depend on a user context.
 * Option 1: Context has no supplier set => autocomplete returns all personS.
 * Option 2: Supplier is set to context => autocomplete returs only personS assigned in Supplier evidence.
 */
export function usePersons(params?: Params) {
  return useAutocompleteSource<PersonAutocomplete>({
    url: `${EvidenceApiUrl.PERSON}/autocomplete`,
    params,
  });
}
/**
 * This Person autocomplete returns all persons if used without filters. It does NOT depend on user context.
 * To get persons assigned to a supplier use this filter {value: supplier?.id,field: 'suppliers.id',operation: ApiFilterOperation.EQ}
 * @param params
 * @returns person
 */
export function useAllPersons(params?: Params) {
  return useAutocompleteSource<PersonAutocomplete>({
    url: `${EvidenceApiUrl.PERSON}/autocomplete/public`,
    params,
  });
}

/**
 * @deprecated This hook is deprecated. It probably wont be removed, it works, but
 * please use usePersons or useAllPersons instead. The only specific UC for this endpoint
 * is to get assigned persons of Primary Journal or Partial Journal - but this is not now used
 * in the app.
 */
export function useAssignedPersons(params?: Params) {
  return useAutocompleteSource<PersonAutocomplete>({
    url: `${EvidenceApiUrl.PERSON}/assignment/autocomplete`,
    params,
  });
}

export function usePersonsInlineTableAutocomplete(params?: Params) {
  return useAutocompleteSource<PersonAutocomplete>({
    url: `${EvidenceApiUrl.PERSON}/autocomplete/public`,
    apiUrl: EvidenceApiUrl.PERSON,
    params,
  });
}
// TODO DELETE THIS
export function usePersonAssignedList({
  skip,
  query,
  primaryJournalId,
  supplierId,
  partialJournalId,
}: /*   userIds, */
//,
{
  skip: boolean;
  // journalEntryId: string;
  query?: string;
  primaryJournalId?: string | undefined;
  partialJournalId?: string | undefined;
  supplierId?: string | undefined;

  /*  userIds?: string[]; */
}) {
  const filters: Filter[] = [];

  if (supplierId) {
    filters.push({
      field: 'supplier.id',
      operation: ApiFilterOperation.EQ,
      value: supplierId,
    });
  }

  if (primaryJournalId) {
    filters.push({
      field: 'primaryJournal.id',
      operation: ApiFilterOperation.EQ,
      value: primaryJournalId,
    });
  }
  if (partialJournalId) {
    filters.push({
      field: 'partialJournal.id',
      operation: ApiFilterOperation.EQ,
      value: partialJournalId,
    });
  }

  if (query) {
    filters.push({
      operation: ApiFilterOperation.FTX,
      value: query,
    });
  }

  const [result, loading, reset, setLoading] = useFetch<ListSource<any>>(
    `${EvidenceApiUrl.PERSON}/assignment/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        size: -1,
        filters,
      }),
      skip,
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

import React, { useContext } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {
  ActionButton,
  DetailContext,
  DetailToolbarButton2,
  DetailToolbarButtonType,
  EvidenceContext,
  FormTextArea,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { useRemoveButtonSchema } from './remove-button-schema';

const DetailToolbarRemoveButton = () => {
  const {
    del,
    source: { data },
    isLocked,
    isExisting,
    refresh,
  } = useContext(DetailContext);
  const { apiUrl } = useContext(EvidenceContext);
  const { hasPermission } = useContext(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const schema = useRemoveButtonSchema();

  const isPrimaryJournal = apiUrl === EvidenceApiUrl.PRIMARY_JOURNAL;

  const isDirectDelete = hasPermission(
    isPrimaryJournal
      ? Permission.PrimaryJournal.PRIMARY_JOURNAL_DELETE
      : Permission.PartialJournal.PARTIAL_JOURNAL_DELETE
  );

  const requestDel = useEventCallback((formValues) => {
    return abortableFetch(`${apiUrl}/${data?.id}/delete/request`, {
      method: 'POST',
      body: JSON.stringify(formValues?.reason),
    });
  });

  return (
    <>
      {isExisting &&
        hasPermission(
          isPrimaryJournal
            ? Permission.PrimaryJournal.PRIMARY_JOURNAL_UPDATE
            : Permission.PartialJournal.PARTIAL_JOURNAL_UPDATE
        ) && (
          <ActionButton
            promptKey={`DELETE_DIALOG${isDirectDelete ? '' : 'REQUEST'}`}
            buttonTooltip={
              // IsLocked if statement fixes mui error
              isLocked
                ? undefined
                : isDirectDelete
                ? 'Smazanie denníku'
                : 'Žiadosť o zmazanie denníku'
            }
            buttonLabel="zmazať"
            ButtonComponent={DetailToolbarButton2}
            buttonDisabled={isLocked}
            buttonProps={{
              type: DetailToolbarButtonType.SECONDARY,
              startIcon: <DeleteOutlinedIcon />,
            }}
            apiCall={isDirectDelete ? undefined : requestDel}
            successMessage={
              isDirectDelete
                ? undefined
                : 'Žiadosť o zmazanie bola úspešne odoslaná'
            }
            onSuccess={
              isDirectDelete
                ? undefined
                : async () => {
                    refresh();
                    showSnackbar(
                      'Žiadosť o zmazanie bola odoslaná.',
                      SnackbarVariant.SUCCESS
                    );
                  }
            }
            submitCallback={isDirectDelete ? del : undefined}
            FormFields={
              isDirectDelete
                ? undefined
                : () => (
                    <FormTextArea
                      name="reason"
                      label="Dôvod zmazania"
                      required
                    />
                  )
            }
            dialogText={
              isDirectDelete
                ? 'Naozaj chcete zmazať denník? Táto akcia je nevratná.'
                : 'Dôjde k odoslaniu žiadosti zmazanie denníka. Ak chcete pokračovať, zadajte dôvod zmazania.'
            }
            dialogTitle="Upozornenie"
            formValidationSchema={isDirectDelete ? undefined : schema}
          />
        )}
    </>
  );
};

export default DetailToolbarRemoveButton;

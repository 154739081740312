/**
 * Region person partial journal assignment permissions
 */
export enum PermissionUserAssignmentPartialJournal {
  /**
   * Prehľad členov dielčieho denníka
   */
  USER_ASSIGNMENT_PARTIAL_JOURNAL_LIST = 'USER_ASSIGNMENT_PARTIAL_JOURNAL_LIST',

  /**
   * Pridávanie členov dielčieho denníka
   */
  USER_ASSIGNMENT_PARTIAL_JOURNAL_CREATE = 'USER_ASSIGNMENT_PARTIAL_JOURNAL_CREATE',

  /**
   * Odstraňovanie členov dielčieho denníka
   */
  USER_ASSIGNMENT_PARTIAL_JOURNAL_DELETE = 'USER_ASSIGNMENT_PARTIAL_JOURNAL_DELETE',
}

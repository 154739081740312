import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DetailContext, Tabs, TabsConfig, UserContext } from '@eas/common-web';
import { useFinalizeKepSignature } from '@components/kep-signature/kep-signature-api';
import { TreeStructureContext } from '@components/tree-structure/tree-structure-provider';
import { Me } from '@models';
import { JournalTypeEnum, Permission } from '@enums';
import { OverviewFields } from './fields/overview';
import { PrimaryJournalSignaturesFields } from './fields/signatures-fields';
import { UsersAssignmentFields } from './fields/users-assignment';

export function Fields() {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const {
    source: { data },
  } = useContext(DetailContext);

  const { updateStructureInPrimary } = useContext(TreeStructureContext);

  useEffect(() => {
    if (data?.id) {
      updateStructureInPrimary(data.id);
    }
  }, [data?.id]);

  // Hook to finish KEP signature in app, after signing at the external signing service.
  useFinalizeKepSignature(JournalTypeEnum.PRIMARY);

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ESD__PRIMARY_JOURNAL__TAB_TITLE__OVERVIEW"
          defaultMessage="Základné údaje"
        />
      ),
      key: 'OVERVIEW',
      validationKeys: [],
      content: <OverviewFields />,
    },
    ...(hasPermission(
      Permission.UserAssignmentPrimaryJournal
        .USER_ASSIGNMENT_PRIMARY_JOURNAL_LIST
    )
      ? [
          {
            label: (
              <FormattedMessage
                id="ESD__PRIMARY_JOURNAL__TAB_TITLE__USERS_ASSIGNMENT"
                defaultMessage="Členovia"
              />
            ),
            key: 'PRIMARY_JOURNAL_USERS_ASSIGNMENT',
            validationKeys: [],
            content: <UsersAssignmentFields />,
          },
        ]
      : []),

    {
      label: (
        <FormattedMessage
          id="ESD__PRIMARY_JOURNAL__TAB_TITLE__SIGNATURES"
          defaultMessage="Podpisy"
        />
      ),
      key: 'PRIMARY_JOURNAL_PERSON_SIGNATURES',
      validationKeys: [],
      content: <PrimaryJournalSignaturesFields />,
    },
  ];
  return <Tabs config={config} />;
}

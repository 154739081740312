import { useContext } from 'react';
import {
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Me, Supplier } from '@models';
import { EvidenceApiUrl, Permission, TenantCode } from '@enums';

export const SUPPLIER_MECHANISM_CATEGORY_DICTIONARY =
  'SUPPLIER_MECHANISM_CATEGORY_DICTIONARY';
export const SUPPLIER_MECHANISM_DICTIONARY = 'SUPPLIER_MECHANISM_DICTIONARY';
export const SUPPLIER_PROFESSION_DICTIONARY = 'SUPPLIER_PROFESSION_DICTIONARY';
export const SUPPLIER_WORK_CATEGORY_DICTIONARY =
  'SUPPLIER_WORK_CATEGORY_DICTIONARY';
export const SUPPLIER_WORK_DICTIONARY = 'SUPPLIER_WORK_DICTIONARY';
export const SUPPLIER_ABSENCE_DICTIONARY = 'SUPPLIER_ABSENCE_DICTIONARY';
export const SUPPLIER_TBPP_DICTIONARY = 'SUPPLIER_TBPP_DICTIONARY';
/* export const SUPPLIER_PERSON_ASSIGNMENT = 'SUPPLIER_PERSON_ASSIGNMENT'; */

const useSupplierMechanismCategorySource = (): HookedSource<Supplier> => {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_MECHANISM_CATEGORY_DICTIONARY]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.MechanismCategory.MECHANISM_CATEGORY_LIST_SUPPLIER
        ),
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.MECHANISM_CATEGORY}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierMechanismsSource = (): HookedSource<Supplier> => {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_MECHANISM_DICTIONARY]: {
      source,
      shouldLoad: () =>
        hasPermission(Permission.Mechanism.MECHANISM_LIST_SUPPLIER),
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.MECHANISMS}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierProfessionsSource = (): HookedSource<Supplier> => {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_PROFESSION_DICTIONARY]: {
      source,
      shouldLoad: () =>
        hasPermission(Permission.Profession.PROFESSION_LIST_SUPPLIER),
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.PROFESSION}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierWorkCategorySource = (): HookedSource<Supplier> => {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_WORK_CATEGORY_DICTIONARY]: {
      source,
      shouldLoad: () =>
        hasPermission(Permission.WorkCategory.WORK_CATEGORY_LIST_SUPPLIER),
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.WORK_CATEGORY}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierWorkSource = (): HookedSource<Supplier> => {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_WORK_DICTIONARY]: {
      source,
      shouldLoad: () => hasPermission(Permission.Work.WORK_LIST_SUPPLIER),
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.WORK}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierAbsencesSource = (): HookedSource<Supplier> => {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_ABSENCE_DICTIONARY]: {
      source,
      shouldLoad: () => hasPermission(Permission.Absence.ABSENCE_LIST_SUPPLIER),
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.ABSENCE}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

const useSupplierTBPPSource = (): HookedSource<Supplier> => {
  const { user, hasPermission } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_TBPP_DICTIONARY]: {
      source,
      shouldLoad: () =>
        user?.tenant?.code === TenantCode.VSD &&
        hasPermission(Permission.TBPP.TBPP_LIST_SUPPLIER),
      modifyBeforeLoad: () => {
        source.setUrl(`${EvidenceApiUrl.TBPP}/list/supplier`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
};

/* function usePersonHookedSource(): HookedSource<Supplier> {
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const source = useScrollableSource();

  return {
    [SUPPLIER_PERSON_ASSIGNMENT]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.PersonAssignmentSupplier.PERSON_ASSIGNMENT_SUPPLIER_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.PERSON}/assignment/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'supplier.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
} */

export const useSupplierDictionaryHookedSources = () => {
  const supplierMechanismCategories = useSupplierMechanismCategorySource();
  const supplierMechanisms = useSupplierMechanismsSource();

  const supplierProfessions = useSupplierProfessionsSource();

  const supplierWorkCategories = useSupplierWorkCategorySource();
  const supplierWork = useSupplierWorkSource();

  const supplierAbsences = useSupplierAbsencesSource();
  const supplierTBPP = useSupplierTBPPSource();

  /*   const supplierPersons = usePersonHookedSource(); */

  return {
    ...supplierMechanismCategories,
    ...supplierMechanisms,
    ...supplierProfessions,
    ...supplierWorkCategories,
    ...supplierWork,
    ...supplierAbsences,
    ...supplierTBPP,
    /*     ...supplierPersons, */
  };
};

import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryEvidence,
  EmptyComponent,
  Evidence,
  UserContext,
} from '@eas/common-web';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TableToolbarImportButtons } from '@components/table-toolbar-import-buttons/table-toolbar-import-buttons';
import { Investor, Me } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { useColumns } from './investor-columns';
import { Fields } from './investor-fields';
import { useValidationSchema } from './investor-schema';

export function Investors() {
  const intl = useIntl();

  const { user } = useContext<UserContext<Me>>(UserContext);

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Investor.INVESTOR_CREATE],
      EDIT: [Permission.Investor.INVESTOR_UPDATE],
      REMOVE: [Permission.Investor.INVESTOR_DELETE],
      REFRESH: [Permission.Investor.INVESTOR_DETAIL],
      SHARE: [Permission.Investor.INVESTOR_DETAIL],
      CHECK: [
        Permission.Investor.INVESTOR_CREATE,
        Permission.Investor.INVESTOR_UPDATE,
      ],
      SAVE: [
        Permission.Investor.INVESTOR_CREATE,
        Permission.Investor.INVESTOR_UPDATE,
      ],
      CLOSE: [
        Permission.Investor.INVESTOR_CREATE,
        Permission.Investor.INVESTOR_UPDATE,
      ],
    },
  });

  const defaultInvestor = {
    tenant: user?.tenant,
  } as Investor;

  const evidence = DictionaryEvidence.useDictionaryEvidence<Investor>({
    version: 1,
    identifier: 'INVESTOR',
    apiProps: {
      url: EvidenceApiUrl.INVESTOR,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__INVESTOR__TABLE__TITLE',
        defaultMessage: 'Investori',
      }),
      showReportButton: false,
      showNamedSettingsButton: true,
      toolbarProps: {
        after: (
          <>
            {
              // TODO: PERMISSION
            }
            {
              <TableToolbarImportButtons
                importPermissionKey={Permission.Investor.INVESTOR_CREATE}
              />
            }
            <MultipleDeleteTableToolbarButton
              deletePermissionKey={Permission.Investor.INVESTOR_DELETE}
            />
          </>
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      initNewItem: () => defaultInvestor,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__INVESTOR__DETAIL__TITLE',
          defaultMessage: 'Investori',
        }),
        showBtn: permissions,
        disableBtn: (button) =>
          ['NEW', 'EDIT'].includes(button) && !user?.tenant,
      },
    },
  });

  return <Evidence {...evidence} />;
}

import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'file.name',
      name: intl.formatMessage({
        id: 'ESD__BOZP_COLLECTION__TABLE__COLUMN__TEXT',
        defaultMessage: 'Soubor',
      }),
      width: 500,
    },
    {
      datakey: 'description',
      name: intl.formatMessage({
        id: 'ESD__BOZP_COLLECTION__TABLE__COLUMN__TEXT',
        defaultMessage: 'Popis',
      }),
      width: 500,
    },
    /*  {
      datakey: 'created',
      name: intl.formatMessage({
        id: 'ESD__NOTES_COLLECTION__COLUMN__CREATED',
        defaultMessage: 'Vytvorenie',
      }),
      width: 200,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'createdBy.name',
      name: intl.formatMessage({
        id: 'ESD__NOTES_COLLECTION__COLUMN__CREATED',
        defaultMessage: 'Autor',
      }),
      width: 200,
    }, */
  ];
}

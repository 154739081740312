import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthContext } from 'react-oauth2-code-pkce';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  NavigationContext,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import {
  AuthConfigContext,
  AuthConfigEnum,
} from '@components/auth/auth-config-provider';
import { EvidenceApiUrl, EvidenceBrowserUrl, Messages } from '@enums';
import { initVsdEasSession, initZsdEasSession, loginDev } from './login-api';
import { useStyles } from './login-styles';

export function Login() {
  const classes = useStyles();
  const { reload } = useContext(UserContext);
  const { authConfig, selectedConfig, setSelectedConfig } =
    useContext(AuthConfigContext);
  const { navigate } = useContext(NavigationContext);
  const { login, loginInProgress, idTokenData, token, idToken } =
    useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const [isIniting, setIsIniting] = useState(false);

  const isTokenVsd = idTokenData?.aud === authConfig.vsd?.registration.clientId;
  const isTokenZsd = idTokenData?.aud === authConfig.zsd?.registration.clientId;

  useEffect(() => {
    const initApp = async () => {
      try {
        let res;

        if (token && isTokenVsd) {
          setIsIniting(true);
          res = await initVsdEasSession(token);
        } else if (idToken && isTokenZsd) {
          setIsIniting(true);
          res = await initZsdEasSession(idToken);
        }
        if (res?.ok) {
          await reload();

          setIsIniting(false);

          navigate(EvidenceBrowserUrl.HOME, true);
        } else if (res && !res.ok) {
          const err = await res.json();
          throw err;
        }
      } catch {
        setIsIniting(false);
        showSnackbar(...Messages.Common.UNEXPECTED_ERROR);
      }
    };

    initApp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, idToken]);

  // These ifs are set so no login screen flickers when logging in
  if (loginInProgress) return <></>;
  if (isIniting) return <></>;

  const handleLoginDev = async () => {
    try {
      const res = await loginDev();

      if (res.ok) {
        await reload();
        navigate('/');
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={classes.page}>
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <Grid container>
            <Grid item>
              <Typography variant="h1">
                <FormattedMessage
                  id="TAPIS__LOGIN__TITLE"
                  defaultMessage="Vitajte späť"
                />
              </Typography>
              <Typography variant="subtitle1">
                <FormattedMessage
                  id="TAPIS__LOGIN__SUBTITLE"
                  defaultMessage="Pokračujte prihlásením!"
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="column" alignItems="center" spacing={1}>
            {selectedConfig ? (
              <>
                <Grid item>
                  <Button
                    onClick={() => login()}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    Prihlásiť
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      localStorage.removeItem('CONFIG');
                      setSelectedConfig(null);
                    }}
                    disabled={!authConfig.vsd}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                  >
                    Zpět
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Button
                    onClick={() => {
                      setSelectedConfig(AuthConfigEnum.VSD);
                      //without this config the auth provider woouldnt know which authconfig to use after the outh login redirects back
                      localStorage.setItem('CONFIG', AuthConfigEnum.VSD);
                    }}
                    disabled
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    VSD
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      setSelectedConfig(AuthConfigEnum.ZSD);
                      localStorage.setItem('CONFIG', AuthConfigEnum.ZSD);
                    }}
                    disabled
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  >
                    ZSD
                  </Button>
                </Grid>
                <Button
                  href={`${window.location.origin}/api/esd/login`}
                  variant="outlined"
                >
                  temporary login
                </Button>

                {process.env.NODE_ENV === 'development' && (
                  <Grid item>
                    <Button
                      onClick={handleLoginDev}
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                    >
                      Login Dev
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </div>
      </Paper>
    </div>
  );
}

import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  AuthoredEvidence,
  DetailContext,
  EmptyComponent,
  Evidence,
  abortableFetch,
  listItemsFactory,
} from '@eas/common-web';
import { PersonType } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { useColumns } from './person-columns';
import { Fields } from './person-fields';
import { useValidationSchema } from './person-schema';

export function Person() {
  const intl = useIntl();

  const { handleSwitchSupplier } = useUserContextSwitch();
  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.Person.PERSON_CREATE],
      EDIT: [Permission.Person.PERSON_UPDATE],
      REMOVE: [Permission.Person.PERSON_DELETE],
      REFRESH: [Permission.Person.PERSON_DETAIL],
      SHARE: [Permission.Person.PERSON_DETAIL],
      CHECK: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
      SAVE: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
      CLOSE: [Permission.Person.PERSON_CREATE, Permission.Person.PERSON_UPDATE],
    },
  });

  const evidence = AuthoredEvidence.useAuthoredEvidence<PersonType>({
    version: 5,
    identifier: 'PERSON',
    apiProps: {
      url: EvidenceApiUrl.PERSON,
      // TODO: This is a temporary fix!!!!!!!!!!
      // Do not use public and come up
      // with functionality of finishing supplierSwitch before the normal person/list is called
      listItems: listItemsFactory<PersonType>({
        listItemsMethod(url, params) {
          handleSwitchSupplier();

          return abortableFetch(url + '/public', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params),
          });
        },
      }),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__PERSON__TABLE__TITLE',
        defaultMessage: 'Osoby',
      }),
      showReportButton: false,
      showNamedSettingsButton: true,
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__PERSON__DETAIL__TITLE',
          defaultMessage: 'Osoby',
        }),
        showBtn: function useShowBtn(btn) {
          const {
            source: { data },
          } = useContext(DetailContext);

          // Removes Remove button if BE sends User to the detail
          // (it happens when the person has user attribute)
          if (btn === 'REMOVE' && !!data?.user) {
            return false;
          }

          return permissions(btn);
        },

        // ActionBar: DataSourceActionbar,
      },
    },
  });

  return <Evidence {...evidence} />;
}

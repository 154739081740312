import * as Yup from 'yup';
import {
  Address,
  Investor,
  PersonType,
  PrimaryJournal,
  Supplier,
} from '@models';

export function useValidationSchema() {
  return Yup.object<PrimaryJournal>().shape({
    construction: Yup.object().shape({
      name: Yup.string().nullable().required(),
      siteId: Yup.string().nullable().required(),
      type: Yup.mixed().nullable().required(),
    }),
    investor: Yup.mixed<Investor>().nullable().required(),
    supervisor: Yup.mixed<PersonType>().nullable().required(),
    leader: Yup.mixed<PersonType>().nullable().required(),
    contractor: Yup.mixed<Supplier>().nullable().required(),

    address: Yup.object<Address>().shape({
      region: Yup.string().nullable().required(),
      municipality: Yup.string().nullable().required(),
    }),
  });
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormPanel, FormTextArea } from '@eas/common-web';

export function NotesCreateDialog() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormTextArea
          name="text"
          label={
            <FormattedMessage
              id="ESD__MECHANISMS__COLLECTION__FIELD_LABEL__TEXT"
              defaultMessage="Poznámka"
            />
          }
        />
      </FormPanel>
    </>
  );
}

import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Me, PartialJournal } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const PARTIAL_JOURNAL_ENTRY = 'PARTIAL_JOURNAL_ENTRY';
export const USERS_ASSSIGNMENT = 'USERS_ASSSIGNMENT';

//
function usePartialJournalEntrySource(): HookedSource<PartialJournal> {
  const source = useScrollableSource();
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [PARTIAL_JOURNAL_ENTRY]: {
      source,
      shouldLoad: () =>
        hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.JOURNAL_ENTRY}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'journal.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
          sort: [
            {
              field: 'validityDate',
              order: 'DESC',
              type: 'FIELD',
            },
          ],
        });
      },
    },
  };
}
function useUsersAssignmentHookedSource(): HookedSource<PartialJournal> {
  const source = useScrollableSource();
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  return {
    [USERS_ASSSIGNMENT]: {
      source,
      shouldLoad: () =>
        hasPermission(
          Permission.UserAssignmentPartialJournal
            .USER_ASSIGNMENT_PARTIAL_JOURNAL_LIST
        ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.USERS}/assignment/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'partialJournal.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export const usePartialJournalHookedSources = () => {
  const journalEntries = usePartialJournalEntrySource();
  const usersAssignments = useUsersAssignmentHookedSource();

  return { ...journalEntries, ...usersAssignments };
};

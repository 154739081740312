/**
 * cíl notifikace - entita, kam redirectujeme po prokliku
 */
export enum NotificationRedirectTarget {
  /**
   * Hlavný deník
   */
  PRIMARY_JOURNAL = 'PRIMARY_JOURNAL',

  /**
   * Dílčí deník
   */
  PARTIAL_JOURNAL = 'PARTIAL_JOURNAL',

  /**
   * Denný záznam
   */
  JOURNAL_ENTRY = 'JOURNAL_ENTRY',
}

import React, { useContext, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {
  AutocompleteProps,
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormContext,
  FormFieldProps,
  FormTextField,
  TextFieldProps,
  Tooltip,
} from '@eas/common-web';

interface FreeTextProps<T> {
  name: string; //Extract<keyof T, 'string'>;
  autocompleteProps: Omit<FormFieldProps<AutocompleteProps<any>>, 'name'>;
  textFieldProps: Omit<FormFieldProps<TextFieldProps>, 'name'>;
}

export const FormFreeTextField = <T,>({
  name,
  autocompleteProps,
  textFieldProps,
}: FreeTextProps<T>) => {
  const {
    mode,
    source: { data },
  } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);
  const [isFreeText, setIsFreeText] = useState(
    !!data?.supervisor?.freetext ?? false
  );
  const resetField = (field: string) => {
    setFieldValue(field, null);
  };

  const textFieldName = `${name}.freetext`;
  const autocompleteName = `${name}.reference`;
  return (
    <>
      {isFreeText ? (
        <FormTextField
          {...textFieldProps}
          name={textFieldName}
          endAdornment={
            mode === DetailMode.EDIT && (
              <Tooltip title="Prepnut na vyber z číselníka">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();

                    resetField(textFieldName);
                    setIsFreeText((prev) => !prev);
                  }}
                  style={{
                    marginRight: '2pt',
                    padding: 1,
                  }}
                >
                  <AutorenewIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )
          }
        />
      ) : (
        <FormAutocomplete
          {...autocompleteProps}
          name={autocompleteName}
          endAdornment={
            mode === DetailMode.EDIT && (
              <Tooltip title="Prepnut na manualne zadanie">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();

                    resetField(autocompleteName);
                    setIsFreeText((prev) => !prev);
                  }}
                  style={{
                    marginRight: '2pt',
                    padding: 1,
                  }}
                >
                  <AutorenewIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )
          }
        />
      )}
    </>
  );
};

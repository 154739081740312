import {
  ApiFilterOperation,
  Filter,
  ListSource,
  Params,
  abortableFetch,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { Role, User, UserAssignmentType } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useRoles() {
  return useAutocompleteSource<Role>({
    url: `${EvidenceApiUrl.ROLES}/autocomplete`,
  });
}
export function useUsers(params?: Params) {
  return useAutocompleteSource<User>({
    url: `${EvidenceApiUrl.USERS}/autocomplete`,
    params,
  });
}
export function useAllUsers(params?: Params) {
  return useAutocompleteSource<User>({
    url: `${EvidenceApiUrl.USERS}/autocomplete/public`,
    params,
  });
}

export function switchSupplier(supplierId?: string) {
  return abortableFetch(`${EvidenceApiUrl.CONTEXT_SWITCH}/supplier`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: supplierId,
  });
}

export function switchTenant(tenantId?: string) {
  return abortableFetch(`${EvidenceApiUrl.CONTEXT_SWITCH}/tenant`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: tenantId,
  });
}

export function useUsersAssignedList({
  skip,
  query,
  primaryJournalId,
  partialJournalId,
}: {
  skip: boolean;
  query?: string;
  primaryJournalId?: string | undefined;
  partialJournalId?: string | undefined;
  supplierId?: string | undefined;
}) {
  const filters: Filter[] = [];

  if (primaryJournalId) {
    filters.push({
      field: 'primaryJournal.id',
      operation: ApiFilterOperation.EQ,
      value: primaryJournalId,
    });
  }
  if (partialJournalId) {
    filters.push({
      field: 'partialJournal.id',
      operation: ApiFilterOperation.EQ,
      value: partialJournalId,
    });
  }

  if (query) {
    filters.push({
      operation: ApiFilterOperation.FTX,
      value: query,
    });
  }

  const [result, loading, reset, setLoading] = useFetch<
    ListSource<UserAssignmentType>
  >(`${EvidenceApiUrl.USERS}/assignment/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      size: -1,
      filters,
    }),
    skip,
  });

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

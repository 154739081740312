/**
 * Region person primary journal assignment permissions
 */
export enum PermissionUserAssignmentPrimaryJournal {
  /**
   * Prehľad členov hlavného denníka
   */
  USER_ASSIGNMENT_PRIMARY_JOURNAL_LIST = 'USER_ASSIGNMENT_PRIMARY_JOURNAL_LIST',

  /**
   * Pridávanie členov hlavného denníka
   */
  USER_ASSIGNMENT_PRIMARY_JOURNAL_CREATE = 'USER_ASSIGNMENT_PRIMARY_JOURNAL_CREATE',

  /**
   * Odstraňovanie členov hlavného denníka
   */
  USER_ASSIGNMENT_PRIMARY_JOURNAL_DELETE = 'USER_ASSIGNMENT_PRIMARY_JOURNAL_DELETE',
}

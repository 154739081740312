import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthProvider as OauthProvider } from 'react-oauth2-code-pkce';
import { SnackbarContext, SnackbarVariant } from '@eas/common-web';
import { Messages } from '@enums';
import { getVsdConfig, getZsdConfig } from './auth-config-provider-api';

export enum AuthConfigEnum {
  VSD = 'VSD',
  ZSD = 'ZSD',
}

const findRedirectUri = (redirectUris: string[], hostname: string) => {
  return redirectUris.find((uri) => uri.includes(hostname)) ?? '';
};

interface OauthConfigs {
  zsd: OauthConfigDto | null;
  vsd: OauthConfigDto | null;
}

interface OauthConfigDto {
  provider: { authorizationUri: string; tokenUri: string; logoutUri: string };
  registration: { clientId: string; redirectUris: string[]; scopes: string[] };
}

interface AuthContextType {
  authConfig: OauthConfigs;
  selectedConfig: AuthConfigEnum | null;
  setSelectedConfig: React.Dispatch<
    React.SetStateAction<AuthConfigEnum | null>
  >;
}

/* In config context we store authConfig, which is a custom made object with two keys (zsd, vsd). And these are coming from BE. At login page we set selected config (VSD|ZSD)
 which rerenders the whole context, so in oauth library provider is set the right config.
 Selected config is also saved to localStorage (at login-new.tsx). That is because when user clicks to log in, is then redirected to external service (microsoft). 
 And after successful login there, user is redirected back to our app (which is loaded from scratch). So initial state of the selectedConfig is then loaded from localStorage.
 I could not find a better way to make oauth library work for both ZSD and VSD companies.  */
export const AuthConfigContext = createContext<AuthContextType>({
  authConfig: {} as OauthConfigs,
  selectedConfig: null,
  setSelectedConfig: () => {},
});

export function AuthConfigProvider({ children }: { children: ReactNode }) {
  const { showSnackbar } = useContext(SnackbarContext);

  const [authConfig, setAuthConfig] = useState<OauthConfigs>({
    zsd: null,
    vsd: null,
  });

  const [selectedConfig, setSelectedConfig] = useState<AuthConfigEnum | null>(
    (localStorage.getItem('CONFIG') as AuthConfigEnum) ?? null
  );

  useEffect(() => {
    const loadAuthConfig = async () => {
      try {
        const [vsdResponse, zsdResponse] = await Promise.all([
          getVsdConfig(),
          getZsdConfig(),
        ]);

        if (!vsdResponse.ok) {
          const error = await vsdResponse.json();

          throw `Nepodarilo sa spustiť aplikáciu. VSD config error: ${error.status}, ${error.code}`;
        }
        if (!zsdResponse.ok) {
          const error = await zsdResponse.json();

          throw `Nepodarilo sa spustiť aplikáciu. ZSD config error: ${error.status}, ${error.code}`;
        }

        const [vsdConfig, zsdConfig] = await Promise.all([
          vsdResponse.json(),
          zsdResponse.json(),
        ]);

        setAuthConfig({ vsd: vsdConfig, zsd: zsdConfig });
      } catch (e) {
        typeof e === 'string'
          ? showSnackbar(e, SnackbarVariant.ERROR)
          : showSnackbar(...Messages.Common.UNEXPECTED_ERROR);
      }
    };

    loadAuthConfig();
  }, [showSnackbar]);

  if (!authConfig.vsd || !authConfig.zsd) return <></>;

  const hostname = window.location.hostname;

  const VSDconfig = {
    authorizationEndpoint: authConfig.vsd.provider.authorizationUri,
    tokenEndpoint: authConfig.vsd.provider.tokenUri,
    clientId: authConfig.vsd.registration.clientId,
    scope: authConfig.vsd.registration.scopes.join(' '),
    redirectUri: 'TODO_TEMPORARY_DUMMY_URI' /* findRedirectUri(
      authConfig.vsd.registration.redirectUris,
      hostname
    ) */,
    decodeToken: false,
    autoLogin: false,
    logoutEndpoint: authConfig.vsd.provider.logoutUri,
    clearUrl: false,
  };
  const ZSDConfig = {
    authorizationEndpoint: authConfig.zsd.provider.authorizationUri,
    tokenEndpoint: authConfig.zsd.provider.tokenUri,
    clientId: authConfig.zsd.registration.clientId,
    scope: authConfig.zsd.registration.scopes.join(' '),
    redirectUri: 'TODO_TEMPORARY_DUMMY_URI' /*  findRedirectUri(
      authConfig.zsd.registration.redirectUris,
      hostname
    ) */,
    decodeToken: false,
    autoLogin: false,
    logoutEndpoint: authConfig.zsd.provider.logoutUri,
    clearUrl: false,
  };

  return (
    <AuthConfigContext.Provider
      value={{ authConfig, selectedConfig, setSelectedConfig }}
    >
      <OauthProvider
        authConfig={
          selectedConfig === AuthConfigEnum.VSD ? VSDconfig : ZSDConfig
        }
      >
        {children}
      </OauthProvider>
    </AuthConfigContext.Provider>
  );
}

import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  Tooltip,
  UserContext,
} from '@eas/common-web';
import { JournalEntry, Me } from '@models';
import { Permission } from '@enums';

const CRUDButton = ({ tooltipLabel, onClick, icon }: any) => {
  return (
    <Tooltip title={tooltipLabel}>
      <IconButton
        onClick={onClick}
        style={{
          marginRight: '2pt',
          padding: 1,
          fontWeight: 'normal',
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

// To be used only in journal entry
const FieldCrudButtons = () => {
  const { mode, save, isLocked, isExisting, startEditing, cancelEditing } =
    useContext<DetailHandle<JournalEntry>>(DetailContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);

  const isEditAllowed =
    hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_UPDATE) &&
    !isLocked &&
    isExisting;

  const isViewMode = mode === DetailMode.VIEW;

  if (!isEditAllowed) return <></>;

  // uncomment and make from FieldCrudButton a custom hook, if auto edit mode is wanted
  /*   useEffect(() => {
    if (
      !isLocked &&
      hasPermission(Permission.JournalEntry.JOURNAL_ENTRY_UPDATE)
    ) {
      setMode(DetailMode.EDIT);
    }
    }, [data?.id, isLocked, hasPermission]); */

  return (
    <>
      {isViewMode ? (
        <CRUDButton
          tooltipLabel="Upraviť"
          onClick={startEditing}
          icon={<EditIcon fontSize="small" />}
        />
      ) : (
        <>
          <CRUDButton
            tooltipLabel="Zrušiť"
            onClick={cancelEditing}
            icon={<UndoIcon fontSize="small" />}
          />
          <CRUDButton
            tooltipLabel="Uložiť"
            onClick={save}
            icon={<SaveIcon fontSize="small" />}
          />
        </>
      )}
    </>
  );
};

export default FieldCrudButtons;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DictionaryEvidence,
  FormAutocomplete,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useTenants } from '@modules/tenant/tenant-api';

export function OverviewFields() {
  const tenants = useTenants();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__PANEL_TITLE"
            defaultMessage="Základné údaje"
          />
        }
      >
        <FormAutocomplete
          name="tenant"
          source={tenants}
          label={
            <FormattedMessage
              id="ESD__PRIMARY_JOURAL__DETAIL__FIELD_LABEL__TENANT"
              defaultMessage="Vlastník"
            />
          }
          disabled
          required
        />
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="ico"
          label={
            <FormattedMessage
              id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__ICO"
              defaultMessage="IČO"
            />
          }
        />
        <FormTextField
          name="dic"
          label={
            <FormattedMessage
              id="ESD__SUPPLIER__DETAIL__FIELD_LABEL__DIC"
              defaultMessage="DIČ"
            />
          }
        />
        <DictionaryEvidence.FieldOrder />
        <DictionaryEvidence.FieldActive />
      </FormPanel>

      {/*       <PersonField
        title={
          <FormattedMessage
            id="ESD__SUPPLIER__DETAIL__PANEL_TITLE_PERSONS"
            defaultMessage="Osoby"
          />
        }
        source={personSource}
        initialValues={{ type: 'SUPPLIER', supplier: { id: data?.id } }}
        defaultExpanded
      /> */}

      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__DETAIL__PANEL_TITLE__HISTORY"
            defaultMessage="História"
          />
        }
      >
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
    </>
  );
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormFileField, FormPanel, FormTextArea } from '@eas/common-web';

export function BOZPReadDialog(props: any) {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ESD__MECHANISMS__COLLECTION__PANEL_LABEL__BASIC"
            defaultMessage="Základné"
          />
        }
      >
        <FormFileField
          name="file"
          label={
            <FormattedMessage
              id="ESD__BOZP__COLLECTION__FIELD_LABEL__FILE"
              defaultMessage="Soubor"
            />
          }
          disabled
        />

        <FormTextArea
          name="description"
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__BOZP_DESCRIPTION"
              defaultMessage="Popis"
            />
          }
        />

        {/*   <FormTableField
          name="signatures"
          columns={signatureColumns}
          ToolbarComponent={({ selectedIndex, setSelectedIndex }) =>
            SignaturesTableFieldToolbar(
              values.id,
              selectedIndex,
              setSelectedIndex,
              props.readApiCall
            )
          }
          maxRows={5}
          labelOptions={{ hide: true }}
          disabled={false}
          layoutOptions={{ noSpacing: true }}
          defaultSort={[
            {
              datakey: 'created',
              field: 'created',
              type: 'FIELD',
              order: 'ASC',
            },
          ]}
        /> */}

        {/*     <FormPanel
          label={
            <FormattedMessage
              id="ESD__JOURNAL_ENTRY_TABLE__PANEL_TITLE__BOZP"
              defaultMessage="BOZP"
            />
          }
        >
          <FormFileField
            name="bozp.file"
            label={
              <FormattedMessage
                id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__BOZP_FILE"
                defaultMessage="Súbor"
              />
            }
            accept={['.pdf', '.doc', '.docx']}
            notifyChange={() => {
              if (bozp?.signatures?.length)
                setFieldValue('bozp.signatures', null);

              if (bozp?.description) setFieldValue('bozp.description', null);
            }}
          />

          <FormTextField
            name="bozp.description"
            label={
              <FormattedMessage
                id="ESD__JOURNAL_ENTRY__DETAIL__FIELD_LABEL__BOZP_DESCRIPTION"
                defaultMessage="Popis"
              />
            }
          />

          <SignaturesTableField
            name="bozp.signatures"
            ToolbarComponent={BozpTableFieldToolbar}
          />
        </FormPanel> */}
      </FormPanel>
    </>
  );
}

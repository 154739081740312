import React, { useContext, useEffect } from 'react';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { EvidenceContext, TableToolbarButton } from '@eas/common-web';
import { JournalEntry, PartialJournal, PrimaryJournal } from '@models';
import {
  LastParentJournalEnum,
  TreeStructureContext,
} from './tree-structure-provider';

type TreeStructureToolbarButtonProps =
  | { isPrimary: boolean; isPartial?: never; isEntry?: never }
  | { isPartial: boolean; isPrimary?: never; isEntry?: never }
  | { isEntry: boolean; isPrimary?: never; isPartial?: never };

export function TreeStructureToolbarButton({
  isPrimary,
  isPartial,
  isEntry,
}: TreeStructureToolbarButtonProps) {
  const {
    treeStructureEnabled,
    toggleStructureInPrimary,
    toggleStructureInPartial,
    toggleStructureInJournalEntry,
    updateStructureInPartial,
    setLastParentJournal,
    structure,
  } = useContext(TreeStructureContext);
  const { detailRef, tableRef } = useContext(EvidenceContext);

  const journal = detailRef.current?.source?.data;

  useEffect(() => {
    if (isPrimary && structure?.primaryJournal) {
      //This highlights parent
      tableRef.current?.setActiveRow(structure.primaryJournal);
    }

    //This also highlights parent of Journal Entry
    if (isPartial && structure?.partialJournal)
      tableRef.current?.setActiveRow(structure.partialJournal);

    if ((isPrimary || isPartial) && journal?.id) {
      if (isPartial) updateStructureInPartial(journal.id);

      setLastParentJournal(
        isPrimary
          ? LastParentJournalEnum.PRIMARY
          : LastParentJournalEnum.PARTIAL
      );
    }
  }, [isPrimary, isPartial, journal?.id]);

  return (
    <TableToolbarButton
      label={<AccountTreeIcon />}
      onClick={() => {
        if (isPrimary) {
          const primaryJournal = journal as PrimaryJournal;

          toggleStructureInPrimary(primaryJournal.id);
        } else if (isPartial) {
          const partialJournal = journal as PartialJournal;

          toggleStructureInPartial(
            partialJournal?.id,
            partialJournal?.parent?.id,
            tableRef
          );
        } else if (isEntry) {
          toggleStructureInJournalEntry(journal as JournalEntry, tableRef);
        }
      }}
      checked={treeStructureEnabled}
      disabled={!detailRef.current?.isExisting && !treeStructureEnabled}
    />
  );
}

import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
  UserContext,
} from '@eas/common-web';
import { TenantCode } from '@enums';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();
  const { user } = useContext(UserContext);

  return [
    /*     {
      datakey: 'user',
      name: intl.formatMessage({
        id: 'ESD__USERS_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Použivateľ',
      }),
      width: 250,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useUsers,
        multiple: false,
        labelMapper: userLabelMapper,
      }),
    }, */
    {
      datakey: 'user.person.name',
      name: intl.formatMessage({
        id: 'ESD__USERS_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Meno',
      }),
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'user.person.surname',
      name: intl.formatMessage({
        id: 'ESD__USERS_COLLECTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Priezvisko',
      }),
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },

    user?.tenant?.code === TenantCode.VSD
      ? {
          datakey: 'user.person.personalNumber',
          name: intl.formatMessage({
            id: 'ESD__USERS_COLLECTION__TABLE__COLUMN__NAME',
            defaultMessage: 'Osobné číslo',
          }),
          width: 200,
          CellComponent: TableFieldCells.TextCell,
        }
      : {
          datakey: 'user.person.kid',
          name: intl.formatMessage({
            id: 'ESD__USERS_COLLECTION__TABLE__COLUMN__NAME',
            defaultMessage: 'KID',
          }),
          width: 200,
          CellComponent: TableFieldCells.TextCell,
        },
    /*  {
      datakey: 'person.profession',
      name: intl.formatMessage({
        id: 'ESD__PERSON_COLLECTION__TABLE__COLUMN__PROFESSION',
        defaultMessage: 'Pracovná pozícia',
      }),
      width: 200,
      CellComponent: TableFieldCells.useAutocompleteCellFactory({
        dataHook: useProfessions,
        multiple: false,
      }),
    }, */
  ];
}

import React, { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { CollectionField, Filter, ScrollableSource } from '@eas/common-web';
import { PersonType } from '@models';
import { AssignmentType, EvidenceApiUrl } from '@enums';
import { UsersAssignDialog } from './dialogs/users-assign-dialog';
import { useColumns } from './users-columns';
import { useValidationSchema } from './users-schema';

interface UsersFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<PersonType>>
  > {
  refFilters?: Filter[];
  source: ScrollableSource<PersonType>;
  initialValues: any;
}

export function UsersField({
  source,
  initialValues,
  ...overrides
}: UsersFieldProps) {
  const primaryJournalId: string | undefined =
    initialValues.type === AssignmentType.PRIMARY_JOURNAL
      ? initialValues.journal.id
      : undefined;

  const partialJournalId: string | undefined =
    initialValues.type === AssignmentType.PARTIAL_JOURNAL
      ? initialValues.journal.id
      : undefined;

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__USERS_COLLECTION"
          defaultMessage="Používatelia"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => EvidenceApiUrl.USERS + '/assignment',
        Dialog: () =>
          UsersAssignDialog({
            primaryJournalId,
            partialJournalId,
          }),
        initValues: () => initialValues,
        useSchema: useValidationSchema,
        dialogWidth: 600,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.USERS}/assignment/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USERS}/assignment/${collectionId}`,
        hide: true,
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USERS}/assignment/${collectionId}`,
        label: 'Odobrať',
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USERS}/assignment/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USERS}/assignment/${collectionId}`,
      }}
      {...overrides}
    />
  );
}

import { useIntl } from 'react-intl';
import {
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { usePersons } from '@modules/person/person-api';
import { useSuppliers } from '@modules/suppliers/supplier-api';
import { PartialJournal } from '@models';
import {
  personColumnLabelMapper,
  personLabelMapper,
} from '@utils/label-mapper';
import {
  usePartialJournalStates,
  usePartialJournalTypes,
} from './partial-journal-api';

export function useColumns(): TableColumn<PartialJournal>[] {
  const intl = useIntl();
  const { columnCreated, columnCreatedBy, columnUpdated, columnUpdatedBy } =
    AuthoredEvidence.useAuthoredColumns<PartialJournal>();

  return [
    {
      datakey: 'parent.construction.name',
      name: intl.formatMessage({
        id: 'ESD_PARTIAL_JOURNAL__COLUMN__NAME',
        defaultMessage: 'Názov stavby',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'parent.construction.siteId',
      name: intl.formatMessage({
        id: 'ESD_PARTIAL_JOURNAL__COLUMN__SITE_ID',
        defaultMessage: 'Číslo stavby',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },

    {
      datakey: 'type',
      filterkey: 'type.id',
      sortkey: 'type.name',
      name: intl.formatMessage({
        id: 'ESD_PARTIAL_JOURNAL__COLUMN__TYPE',
        defaultMessage: 'Typ dielčieho denníku',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        usePartialJournalTypes
      ),
      valueMapper: TableCells.useSelectCellFactory(usePartialJournalTypes),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'supplier',
      sortkey: 'supplier.name',
      filterkey: 'supplier.id',
      name: intl.formatMessage({
        id: 'ESD__PARTIAL_JOURNAL__TABLE__COLUMN__SUPPLIER',
        defaultMessage: 'Dodávateľ',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(useSuppliers),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'leader',
      sortkey: 'leader.name',
      filterkey: 'leader.id',
      name: intl.formatMessage({
        id: 'ESD__PARTIAL_JOURNAL__TABLE__COLUMN__LEADER',
        defaultMessage: 'Stavbyvedúci dodávateľa',
      }),
      width: 250,
      CellComponent: TableCells.TextCell,
      valueMapper: personColumnLabelMapper,
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          usePersons,
          personLabelMapper
        ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'deputyLeader',
      sortkey: 'deputyLeader.name',
      filterkey: 'deputyLeader.id',
      name: intl.formatMessage({
        id: 'ESD__PARTIAL_JOURNAL__TABLE__COLUMN__DEPUTY_LEADER',
        defaultMessage: 'Vedúci prác dodávateľa',
      }),
      width: 250,
      CellComponent: TableCells.TextCell,
      valueMapper: personColumnLabelMapper,
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          usePersons,
          personLabelMapper
        ),
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'team',
      name: intl.formatMessage({
        id: 'ESD_PARTIAL_JOURNAL__COLUMN__NAME',
        defaultMessage: 'Tím dodávateľa',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'state',
      filterkey: 'state.id',
      sortkey: 'state.name',
      name: intl.formatMessage({
        id: 'ESD__PARTIAL_JOURNAL__COLUMN__STATE',
        defaultMessage: 'Stav dielčieho denníku',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterMultipleSelectCellFactory(
        usePartialJournalStates
      ),
      valueMapper: TableCells.useSelectCellFactory(usePartialJournalStates),
      sortable: true,
      filterable: true,
    },
    {
      datakey: columnCreated.datakey,
      name: columnCreated.name,
      width: 120,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
    },
    columnCreatedBy,
    columnUpdated,
    columnUpdatedBy,
  ];
}

import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import { UsersField } from '@composite/users-collection/users-field';
import { PrimaryJournal } from '@models';
import { AssignmentType } from '@enums';
import { useUserAssignments } from '@utils/use-user-assignments';
import { USER_ASSSIGNMENT } from '../primary-journal-hooked-sources';

export function UsersAssignmentFields() {
  const {
    source: { data },
    mode,
    isLocked,
    isExisting,
  } = useContext<DetailHandle<PrimaryJournal>>(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { isPrimaryJournalAssigned } = useUserAssignments();

  const userAssignment = hookedSources[USER_ASSSIGNMENT]?.source;

  const hasMissingPermissions = !isPrimaryJournalAssigned(data?.id);

  useEffect(() => {
    if (hasMissingPermissions) {
      showSnackbar(
        <FormattedMessage
          id="ESD__PRIMARY_JOURNAL__DETAIL__ASSIGNMENTS_UNAVAILABLE__SNACKBAR"
          defaultMessage="Chýbajúce oprávnenia - Nie ste priamym členom aktuálne otvoreného hlavného denníka preto nemáte oprávnenie k zobrazovaniu jeho členov"
        />,
        SnackbarVariant.WARNING,
        true
      );
    }
  }, [hasMissingPermissions, showSnackbar]);

  return (
    <>
      {isExisting && !hasMissingPermissions && (
        <UsersField
          source={userAssignment}
          disabled={mode !== DetailMode.VIEW || isLocked}
          initialValues={{
            type: AssignmentType.PRIMARY_JOURNAL,
            journal: { id: data?.id },
          }}
        />
      )}
    </>
  );
}

import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import exceptions from 'src/enums/messages/exception';
import {
  CollectionField,
  DetailContext,
  EvidenceContext,
} from '@eas/common-web';
import { NOTES } from '@modules/journal-entry/journal-entry-hooked-sources';
import { EvidenceApiUrl } from '@enums';
import { NotesCreateDialog } from './dialogs/notes-create-dialog';
import { useColumns } from './notes-colums';
import { useValidationSchema } from './notes-schema';

export function NotesField() {
  // const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const {
    source: { data },
  } = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);

  const source = hookedSources[NOTES]?.source;

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ESD__FIELD_LABEL__NOTES"
          defaultMessage="Poznámky"
        />
      }
      // defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => EvidenceApiUrl.JOURNAL_ENTRY + '/note',
        Dialog: NotesCreateDialog,
        initValues: () => ({
          journalEntry: { id: data.id },
        }),
        useSchema: useValidationSchema,
        dialogWidth: 650,
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
        Dialog: NotesCreateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 650,
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
        label: 'Odobrať',
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.JOURNAL_ENTRY}/note/${collectionId}`,
      }}
      exceptions={exceptions}
    />
  );
}
